
import { onMounted, ref, watch } from "vue";
import ApiService from "../../core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";

export default {
  name: "EntitySelect",
  props: {
    entity_id: {
      type: String,
      default: "",
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:entity_id"],

  setup(props) {
    const { t } = useI18n();
    const inputEntity = ref({
      loading: false,
      options: [],
      list: [],
    });

    const isEntityRequired = (value) => {
      if (props.required && !value) {
        return t("rentity");
      }
      return true;
    };

    const { errorMessage, value: element_id } = useField(
      "entity",
      isEntityRequired
    );

    const getEntity = () => {
      ApiService.get("/api/entities").then(({ data }) => {
        inputEntity.value.list = data;
        inputEntity.value.options = data;
      });
    };
    const selectEntity = (query) => {
      if (query !== "") {
        inputEntity.value.loading = true;
        setTimeout(() => {
          inputEntity.value.loading = false;
          inputEntity.value.options = inputEntity.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
        }, 200);
      } else {
        inputEntity.value.options = [];
      }
    };

    watch(
      () => props.entity_id,
      (first) => {
        if (first) {
          element_id.value = first;
        }
      }
    );

    onMounted(() => getEntity());

    return {
      element_id,
      inputEntity,
      errorMessage,
      getEntity,
      selectEntity,
    };
  },
};
