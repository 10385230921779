import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "form-label fs-6 fw-bolder text-dark"
}
const _hoisted_2 = { class: "text-danger" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($props.showLabel)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, _toDisplayString(_ctx.$t("maritime_services")), 1))
      : _createCommentVNode("", true),
    _createVNode(_component_el_select, {
      modelValue: $setup.element_id,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.element_id) = $event)),
      name: "service",
      class: "form-select-custom w-100 p-0",
      size: $props.size,
      clearable: "",
      placeholder: `${_ctx.$t('iselect')}`,
      filterable: "",
      remote: "",
      "reserve-keyword": "",
      "remote-method": $setup.selectMaritimeService,
      loading: $setup.inputMaritimeService.loading,
      onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:maritime_service_id', $event)))
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.inputMaritimeService.options, (maritime_service) => {
          return (_openBlock(), _createBlock(_component_el_option, {
            key: maritime_service.id,
            label: maritime_service.name,
            value: maritime_service.id
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(`${maritime_service.id} ${maritime_service.name}`), 1)
            ]),
            _: 2
          }, 1032, ["label", "value"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue", "size", "placeholder", "remote-method", "loading"]),
    _createElementVNode("span", _hoisted_2, _toDisplayString($setup.errorMessage), 1)
  ], 64))
}