
import { onMounted, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";

export default {
  name: "MaritimeServicesSelect",
  props: {
    maritime_service_id: {
      type: String,
      default: "",
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:maritime_service_id"],

  setup(props) {
    const { t } = useI18n();
    const inputMaritimeService = ref({
      loading: false,
      options: [],
      list: [],
    });

    const isServiceRequired = (value) => {
      if (props.required && !value) {
        return t("rservice");
      }
      return true;
    };

    const { errorMessage, value: element_id } = useField(
      "service",
      isServiceRequired
    );

    const getMaritimeService = () => {
      ApiService.get("/api/maritime-services").then(({ data }) => {
        inputMaritimeService.value.list = data;
        inputMaritimeService.value.options = data;
      });
    };
    const selectMaritimeService = (query) => {
      if (query !== "") {
        inputMaritimeService.value.loading = true;
        setTimeout(() => {
          inputMaritimeService.value.loading = false;
          inputMaritimeService.value.options =
            inputMaritimeService.value.list.filter(
              (item: Record<string, any>) => {
                return (
                  item.name.toLowerCase().indexOf(query.toLowerCase()) > -1
                );
              }
            );
        }, 200);
      } else {
        inputMaritimeService.value.options = [];
      }
    };

    watch(
      () => props.maritime_service_id,
      (first) => {
        if (first) {
          element_id.value = first;
        }
      }
    );

    onMounted(() => getMaritimeService());

    return {
      element_id,
      inputMaritimeService,
      errorMessage,
      getMaritimeService,
      selectMaritimeService,
    };
  },
};
