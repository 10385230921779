
import { onMounted, ref, watch } from "vue";
import ApiService from "../../core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";

export default {
  name: "OperationTypesSelect",
  props: {
    operation_type_id: {
      type: String,
      default: "",
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:operation_type_id"],

  setup(props) {
    const { t } = useI18n();
    const inputOperationType = ref({
      loading: false,
      options: [],
      list: [],
    });

    const isOperationRequired = (value) => {
      if (props.required && !value) {
        return t("roperationtype");
      }
      return true;
    };

    const { errorMessage, value: element_id } = useField(
      "operation",
      isOperationRequired
    );

    const getOperationType = () => {
      ApiService.get("/api/smoperation_types").then(({ data }) => {
        inputOperationType.value.list = data;
        inputOperationType.value.options = data;
      });
    };
    const selectOperationType = (query) => {
      if (query !== "") {
        inputOperationType.value.loading = true;
        setTimeout(() => {
          inputOperationType.value.loading = false;
          inputOperationType.value.options =
            inputOperationType.value.list.filter(
              (item: Record<string, any>) => {
                return (
                  item.name.toLowerCase().indexOf(query.toLowerCase()) > -1
                );
              }
            );
        }, 200);
      } else {
        inputOperationType.value.options = [];
      }
    };

    watch(
      () => props.operation_type_id,
      (first) => {
        if (first) {
          element_id.value = first;
        }
      }
    );

    onMounted(() => getOperationType());

    return {
      element_id,
      inputOperationType,
      errorMessage,
      getOperationType,
      selectOperationType,
    };
  },
};
