
import { onMounted, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";

export default {
  name: "ContactSelect",
  props: {
    contact_id: {
      type: String,
      default: "",
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
    customer_id: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:contact_id"],

  setup: function (props) {
    const { t } = useI18n();
    const inputContact = ref({
      loading: false,
      options: [],
      list: [] as any,
    });

    const isContactRequired = (value) => {
      if (props.required && !value) {
        return t("rcontact");
      }
      return true;
    };

    const { errorMessage, value: element_id } = useField(
      "contact",
      isContactRequired
    );

    const getContact = () => {
      ApiService.get("/api/resource/contact").then(({ data }) => {
        console.log('data', data)
        inputContact.value.list = data;
        inputContact.value.options = data;
      });
    };
    const selectContact = (query) => {
      if (query !== "") {
        inputContact.value.loading = true;
        setTimeout(() => {
          inputContact.value.loading = false;
          inputContact.value.options = inputContact.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
        }, 200);
      } else {
        inputContact.value.options = [];
      }
    };

    watch(
      () => props.contact_id,
      (first) => {
        if (first) {
          element_id.value = first;
        }
      }
    );

    watch(
      () => props.customer_id,
      (first) => {
        if (first) {
          console.log('aaa', first)
          console.log('inputContact.value.options', inputContact.value.options)
          inputContact.value.options = inputContact.value.list.filter(
            (x) => x.entity_id === first
          );
        }
      }
    );

    onMounted(() => getContact());

    return {
      element_id,
      inputContact,
      errorMessage,
      getContact,
      selectContact,
    };
  },
};
