
import { onMounted, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";

export default {
  name: "MaritimeFileTypeSelect",
  props: {
    maritime_file_type_id: {
      type: String,
      default: "",
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:maritime_file_type_id"],

  setup(props) {
    const { t } = useI18n();
    const inputMaritimeFileType = ref({
      loading: false,
      options: [],
      list: [],
    });

    const isFileTypeRequired = (value) => {
      if (props.required && !value) {
        return t("rmaritimefilestype");
      }
      return true;
    };

    const { errorMessage, value: element_id } = useField(
      "fileType",
      isFileTypeRequired
    );

    const getMaritimeFileType = () => {
      ApiService.get("/api/maritime-file-types").then(({ data }) => {
        inputMaritimeFileType.value.list = data;
        inputMaritimeFileType.value.options = data;
      });
    };
    const selectMaritimeFileType = (query) => {
      if (query !== "") {
        inputMaritimeFileType.value.loading = true;
        setTimeout(() => {
          inputMaritimeFileType.value.loading = false;
          inputMaritimeFileType.value.options =
            inputMaritimeFileType.value.list.filter(
              (item: Record<string, any>) => {
                return (
                  item.name.toLowerCase().indexOf(query.toLowerCase()) > -1
                );
              }
            );
        }, 200);
      } else {
        inputMaritimeFileType.value.options = [];
      }
    };

    watch(
      () => props.maritime_file_type_id,
      (first) => {
        if (first) {
          element_id.value = first;
        }
      }
    );

    onMounted(() => getMaritimeFileType());

    return {
      props,
      element_id,
      inputMaritimeFileType,
      errorMessage,
      getMaritimeFileType,
      selectMaritimeFileType,
    };
  },
};
