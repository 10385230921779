
import { onMounted, ref, watch } from "vue";
import ApiService from "../../core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";

export default {
  name: "OfficeSelect",
  props: {
    office_id: {
      type: String,
      default: "",
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:office_id"],

  setup(props) {
    const { t } = useI18n();
    const inputOffice = ref({
      loading: false,
      options: [],
      list: [],
    });

    const isOfficeRequired = (value) => {
      if (props.required && !value) {
        return t("rofficeid");
      }
      return true;
    };

    const {
      errorMessage,
      value: element_id,
      meta,
    } = useField("office", isOfficeRequired);

    const getOffices = () => {
      ApiService.get("/api/offices").then(({ data }) => {
        inputOffice.value.list = data;
        inputOffice.value.options = data;
      });
    };
    const selectOffice = (query) => {
      if (query !== "") {
        inputOffice.value.loading = true;
        setTimeout(() => {
          inputOffice.value.loading = false;
          inputOffice.value.options = inputOffice.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
        }, 200);
      } else {
        inputOffice.value.options = [];
      }
    };

    watch(
      () => props.office_id,
      (first) => {
        if (first) {
          element_id.value = first;
        }
      }
    );

    onMounted(() => getOffices());

    return {
      element_id,
      inputOffice,
      errorMessage,
      meta,
      getOffices,
      selectOffice,
    };
  },
};
